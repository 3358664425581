import React, { FC, useContext, useRef } from 'react'
import { TranslationContext } from '@/context'
import { Select } from 'Components/ui'

const companySizeOptions = [
	'1 - 10',
	'11 - 50',
	'51 - 100',
	'101 - 500',
	'501 - ...',
]
interface Option {
  value: string;
  label: string;
}
interface Props {
  size?: string;
  setSize: (value: string) => void;
  isValid: boolean;
}

export const BusinessSizeSelect: FC<Props> = ({ size = '', setSize, isValid }) => {
	const ref = useRef()
	const translation = useContext(TranslationContext)

	const options: Option[] = companySizeOptions.map((value) => ({ value, label: value }))

	const selectOption = ({ value }: Option) => {
		setSize(value)
	}

	const selectedSizeOption = size ? { value: size, label: size } : undefined

	return (
		<Select
			selectRef={ref}
			className="select"
			name="Company size"
			onChange={selectOption}
			value={selectedSizeOption}
			label={`${translation.t('Size of company')}*`}
			options={options}
			isValid={isValid}
			placeholder={translation.t('How many people work in your company?')}
		/>
	)
}
