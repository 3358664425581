import ls from '../../local-storage/local-storage'
import logger from '../../logger/logger'

import {
	patchCurrentStepChanges,
	setCurrentStep,
	setCurrentStepFormErrors,
	showConfirmationPopup,
	updateProgress,
} from './app.actions'
import {
	selectAppState,
	selectCurrentStep,
	selectCurrentStepData,
	selectCurrentStepHasChanges,
	selectIsFinalStep,
	selectMainFlowSteps,
} from './app.selectors'
import { getStepIndex } from './app.utils'
import { resetReservationState } from '../reservation/reservation.actions'
import {
	clearSelectedCleaningDateTimes,
	currentCleaningDateTimeToArray,
} from '../reservation/cleaning-mid-stay/cleaning-mid-stay.actions'
import getStepFormErrorsSelector from '../step-specific/step-form-errors.selectors'

import config, { getConfig } from '../../globals/constants'
import { hideEmailVerificationDialog } from '../ui/ui.actions'

const {
	STEPS,
} = config

const { CHECK_IN } = STEPS
const CHECK_IN_STEPS = {
	...CHECK_IN.MAIN,
	...CHECK_IN.SUB,
	...CHECK_IN.COMPLETED,
}

const finalizeGoToStep = (stepData, options) => (dispatch) => {
	const { step } = stepData
	const nextStepIsSubStep = Object.values(STEPS.CHECK_IN.SUB).includes(step)

	if (!nextStepIsSubStep) {
		const nextStepIsCompleteStep = Object.values(STEPS.CHECK_IN.COMPLETED).includes(step)

		if (nextStepIsCompleteStep) {
			ls.removeAllData()
		} else {
			ls.setItem('step', step)
		}
	}

	if (!options?.toSkipStateReset) {
		dispatch(resetReservationState())
	}

	dispatch(setCurrentStepFormErrors([]))
	dispatch(setCurrentStep(stepData, options))
	dispatch(updateProgress())
	window.scrollTo(0, 0)
}

const goToStepWithConfirmationPrompt = (stepData, options, onCancel) => (dispatch, getState) => {
	const { confirmationMessage } = options
	const currentStepHasChanges = selectCurrentStepHasChanges(getState())

	if (confirmationMessage && currentStepHasChanges) {
		dispatch(
			showConfirmationPopup(
				confirmationMessage,
				() => dispatch(finalizeGoToStep(stepData, options)),
				onCancel,
			),
		)

		return
	}

	dispatch(finalizeGoToStep(stepData, options))
}

const getNextStep = (currentStep, mainFlowSteps, isFinalStep) => {
	if (isFinalStep) {
		return CHECK_IN_STEPS['success']
	}

	const currentStepIdx = getStepIndex(mainFlowSteps, currentStep)
	return currentStepIdx === -1 ? null : mainFlowSteps[currentStepIdx + 1]
}
const patchCurrentStep = (callback) => (dispatch, getState) => {
	const currentStep = selectCurrentStep(getState())

	switch (currentStep) {
		case CHECK_IN_STEPS['add-card']:
			dispatch(patchCurrentStepChanges(callback))
			break
		default:
			logger.warn(`Unknown step: ${currentStep}`)
			break
	}
}

const stepSwitch = (currentStep, stepData, options) => (dispatch, getState) => {
	const isFinalStep = selectIsFinalStep(getState())

	const finalize = (internalOptions) => {
		dispatch(hideEmailVerificationDialog())

		dispatch(finalizeGoToStep(
			stepData,
			{
				...options,
				...internalOptions,
			},
		))
	}

	switch (currentStep) {
		case CHECK_IN_STEPS['start']:
			finalize()
			break
		case CHECK_IN_STEPS['main-guest-contacts']:
			dispatch(patchCurrentStepChanges(finalize))
			break
		case CHECK_IN_STEPS['business-lead']:
			dispatch(patchCurrentStepChanges(finalize))
			break
		case CHECK_IN_STEPS['main-guest-identification']:
			dispatch(patchCurrentStepChanges(finalize))
			break
		case CHECK_IN_STEPS['guests']:
			dispatch(patchCurrentStepChanges(finalize))
			break
		case CHECK_IN_STEPS['main-guest-details']:
			dispatch(patchCurrentStepChanges(finalize))
			break
		case CHECK_IN_STEPS['additional-guest-details']:
			dispatch(patchCurrentStepChanges(finalize))
			break
		case CHECK_IN_STEPS['times']: {
			if (isFinalStep) {
				dispatch(patchCurrentStepChanges(finalize))
				break
			}

			finalize()
			break
		}
		case CHECK_IN_STEPS['room-upgrade']:
		case CHECK_IN_STEPS['product-property-unit-category-upgrade']:
		case CHECK_IN_STEPS['product-property-unit-category-upgrade-free']:
		case CHECK_IN_STEPS['products']: {
			const isOpeningSingleProductView = !!stepData.productKey || !!stepData.productKeys

			if (isOpeningSingleProductView) {
				finalize({ toSkipStateReset: true })
				break
			}

			if (isFinalStep) {
				dispatch(patchCurrentStepChanges(finalize))
				break
			}

			finalize()
			break
		}
		case CHECK_IN_STEPS['product-breakfast']:
		case CHECK_IN_STEPS['product-parking-indoor']:
		case CHECK_IN_STEPS['product-mealkit-platform-pro']:
		case CHECK_IN_STEPS['product-meal-kit-clean-kitchen-vegan-dan-dan-noodles-2']:
		case CHECK_IN_STEPS['product-sauna-kit']:
		case CHECK_IN_STEPS['product-stocked-fridge']:
			finalize({ toSkipStateReset: true })
			break
		case CHECK_IN_STEPS['summary']:
			dispatch(patchCurrentStepChanges(finalize))
			break
		case CHECK_IN_STEPS['add-card']:
			dispatch(patchCurrentStepChanges())
			break
		case CHECK_IN_STEPS['success']:
			finalize()
			break
		case CHECK_IN_STEPS['guest-area-cleaning-mid-stay-select-time']:
			dispatch(currentCleaningDateTimeToArray())
			finalize({ toSkipStateReset: true })
			break
		case CHECK_IN_STEPS['guest-area-cleaning-mid-stay-confirm']:
			dispatch(patchCurrentStepChanges(() => {
				dispatch(clearSelectedCleaningDateTimes())
				finalize({ toSkipStateReset: true })
			}))
			break
		default:
			logger.warn(`Unknown step: ${currentStep}`)
			break
	}
}

const goToStep = (data, options) => (dispatch, getState) => {
	const currentStep = selectCurrentStep(getState())
	const mainFlowSteps = selectMainFlowSteps(getState())
	const isFinalStep = selectIsFinalStep(getState())
	const nextStep = data?.step || getNextStep(currentStep, mainFlowSteps, isFinalStep)
	const stepData = {
		...data,
		step: nextStep,
	}

	if (options?.confirmationMessage) {
		dispatch(goToStepWithConfirmationPrompt(stepData, options))
		return
	}

	if (options?.toSkipPatch) {
		dispatch(finalizeGoToStep(stepData, options))
		return
	}

	const selectStepFormErrors = getStepFormErrorsSelector(currentStep)
	const formErrors = selectStepFormErrors(getState())
	if (formErrors?.length) {
		dispatch(setCurrentStepFormErrors(formErrors, true))
		return
	}

	dispatch(stepSwitch(currentStep, stepData, options))
}

const goToPrevStep = (options) => (dispatch, getState) => {
	const {
		stepDataIndex,
		stepDataHistory,
	} = selectAppState(getState())
	const {
		withApiPatch,
		resetStepHistoryTip,
	} = options || {}

	let prevStepData

	if (stepDataIndex > 0) {
		prevStepData = stepDataHistory[stepDataIndex - 1]
	} else {
		const mainFlowSteps = selectMainFlowSteps(getState())
		const currentStep = stepDataHistory[stepDataIndex].step
		const prevStep = mainFlowSteps[mainFlowSteps.indexOf(currentStep) - 1]
		prevStepData = { step: prevStep }
	}

	if (withApiPatch) {
		dispatch(
			goToStep(
				prevStepData,
				{
					toPrevious: true,
					resetStepHistoryTip,
				},
			),
		)
		return
	}

	dispatch(
		goToStep(
			prevStepData,
			{
				confirmationMessage: getConfig().COMMON_STRINGS.leaveStepWithoutSaving,
				toPrevious: true,
				resetStepHistoryTip,
			},
		),
	)
}

const handleBrowserBackForwardButton = ({ state: stepData }, leaveStepWithoutSaving) => (dispatch, getState) => {
	const currentStep = selectCurrentStep(getState())

	if (currentStep === CHECK_IN_STEPS['guest-area']) {
		return
	}

	const currentStepData = selectCurrentStepData(getState())

	dispatch(goToStepWithConfirmationPrompt(
		stepData,
		{ confirmationMessage: leaveStepWithoutSaving },
		() => {
			window.history.pushState(currentStepData, '', '')
		},
	))
}

export {
	goToPrevStep,
	goToStep,
	patchCurrentStep,
	handleBrowserBackForwardButton,
}
