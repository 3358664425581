import styled from 'styled-components'
import { COLORS, generateMediaQuery } from '@/globals/global.styles'

interface ContentProps {
	readonly withHeader: boolean;
}

const HEADER_HEIGHT = '41px' as const
export const contentPadding = '16px' as const

export const PageViewStyled = styled.div`
	padding-bottom: 16px;
`

export const Card = styled.div`
	margin: 0 0 16px 0;
	background-color: ${COLORS.background.primary};
`

export const CardContent = styled.div`
	padding: ${contentPadding};
`

export const Content = styled.div<ContentProps>`
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
	padding-top: ${(props) => (props.withHeader ? HEADER_HEIGHT : '16px')};

	${(props) => (!props.withHeader
		&& `
			${generateMediaQuery.allMobile(`
				padding-top: 0;
			`)}
		`
	)}
`
