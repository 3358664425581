import React, { FC, useContext, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectReservation } from 'Redux/reservation/reservation.selectors'
import { selectAppState, selectIsSubmitting } from 'Redux/app/app.selectors'
import { goToPrevStep, goToStep } from 'Redux/app/go-to-step.actions'
import { ReservationState } from 'Types/reservation-state.types'
import { RootState } from 'Redux/store'
import { ContentHeader, HeaderSticky, PrevNextButtonsSticky } from 'Components/view-structure.component'
import PageView from 'Components/PageView'
import { AppState } from 'Types/app-state.types'
import { TranslationContext } from '@/context'
import { FormInput } from 'Components/ui'
import styled from 'styled-components'
import { BusinessSizeSelect } from 'Views/check-in-views/business-lead-view/business-size-select'
import { removeBusinessLead } from 'Redux/business-lead/business-lead.actions'
import { updateCurrentStepField } from 'Redux/app/app.actions'
import { selectBusinessLead } from 'Redux/business-lead/business-lead.selectors'
import Checkbox from 'Components/base/checkbox/Checkbox'
import { selectMainGuestCustomer } from 'Redux/reservation/guests/guests.selectors'
import { FONT_WEIGHTS } from 'Globals/global.styles'

interface StateProps {
	appState: AppState;
	reservation: ReservationState['reservation'];
	businessLead: ReservationState['businessLeadCapture'] | null;
	mainGuestCustomer: ReservationState['mainGuestCustomer'];
	isSubmitting?: boolean;
}

interface DispatchProps {
	goToNextStep: (options?: any) => void;
	updateField: (fieldName: string, data: string) => void;
	removeBusinessLead: () => void;
	goToPrevStep: () => void;
}

const FormContent = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 20px;
`

const FormInputStyled = styled(FormInput)`

	input:disabled {
        color: grey;
    }
`
const CheckboxContainer = styled('div')`
    margin-top: 8px;
    margin-bottom: 20px;
`

const Sections = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 40px;
`

const Description = styled('div')`
	font-size: 14px;
`

const ContentMainHeading = styled.h2`
	margin: 0 0;
	font-size: 20px;
	font-weight: ${FONT_WEIGHTS.normal};
`
interface BusinessLeadViewProps extends StateProps, DispatchProps { }

const BusinessLeadViewComponent: FC<BusinessLeadViewProps> = ({
	appState: {
		progressData,
	},
	isSubmitting,
	reservation,
	goToPrevStep,
	goToNextStep,
	businessLead,
	removeBusinessLead,
	updateField,
	mainGuestCustomer,
}) => {
	const translation = useContext(TranslationContext)
	const {
		phone: propertyPhone,
	} = reservation?.property || {}

	const {
		companyName,
		companySizeRange,
		contactPerson,
		contactEmail,
		contactJobTitle,
	} = businessLead || {}

	const [hasClickedNext, setHasClickedNext] = useState(false)
	const [guestIsContactPerson, setGuestIsContactPerson] = useState(contactEmail === mainGuestCustomer.email)
	const [preservedContact, setPreservedContactPerson] = useState({ contactPerson, contactEmail })

	const skipToNextStep = () => {
		removeBusinessLead()
		goToNextStep({ toSkipPatch: true })
	}

	const isValid = (value: string = '') => value?.length >= 2
	const isInvalid = (value = '') => (value === null ||	value?.length < 2) && hasClickedNext === true

	const isValidEmail = (value = '') => value?.length >= 2
		&& value.includes('@') && value.includes('.')

	const isInvalidEmail = (value = '') => !isValidEmail(value) && hasClickedNext === true

	const isFormValid = () => {
		return isValid(companyName)
		&& isValid(companySizeRange)
		&& isValid(contactPerson)
		&& isValidEmail(contactEmail)
		&& isValid(contactJobTitle)
	}

	const toggleGuestIsContactPerson = () => {
		const newValue = !guestIsContactPerson
		setGuestIsContactPerson(newValue)

		if (newValue) {
			setPreservedContactPerson({ contactPerson, contactEmail })

			updateField('contactPerson', `${mainGuestCustomer.firstName} ${mainGuestCustomer.lastName}`)
			updateField('contactEmail', mainGuestCustomer.email ?? '')
		} else {
			const preservedContactPerson = preservedContact.contactEmail === mainGuestCustomer.email ? '' : preservedContact.contactPerson
			const preservedContactEmail = preservedContact.contactEmail === mainGuestCustomer.email ? '' : preservedContact.contactEmail

			updateField('contactPerson', preservedContactPerson ?? '')
			updateField('contactEmail', preservedContactEmail ?? '')
		}
	}

	const scrollToInvalidInput = () => {
		const elements = document.getElementsByClassName('invalid-form-input')
		const firstInvalidElement = elements?.[0]

		if (firstInvalidElement) {
			firstInvalidElement.scrollIntoView({ behavior: 'smooth' })
		}
	}

	const handleClickOnNext = (event: React.FormEvent<any>) => {
		event.preventDefault()
		event.stopPropagation()

		setHasClickedNext(true)

		if (isFormValid()) {
			goToNextStep()
		} else {
			scrollToInvalidInput()
		}
	}

	return (
		<form
			onSubmit={handleClickOnNext}
		>
			<PageView
				Header={() => (
					<HeaderSticky
						{...progressData}
						onBackButtonClick={goToPrevStep}
						contactButton={{ url: `tel:${propertyPhone}` }}
					/>
				)}
				FooterSection={() => (
					<PrevNextButtonsSticky
						onPrevClick={goToPrevStep}
						disableNextButton={isSubmitting}
						nextButtonText={translation.t('Next')}
						hideSkipButton={false}
						onSkipClick={skipToNextStep}
					/>
				)}
			>

				<ContentHeader
					mainText={translation.t('Business with Bob W')}
					subText={translation.t('Learn more about our corporate rates & benefits.')}
				/>
				<Sections>
					<FormContent>
						<ContentMainHeading>
							{translation.t('Details of your business')}
						</ContentMainHeading>

						<FormInputStyled
							label={`${translation.t('Company name')}*`}
							name="companyName"
							value={companyName}
							handleChange={(value: string) => updateField('companyName', value)}
							isValid={!isInvalid(companyName)}
						/>

						<BusinessSizeSelect
							size={companySizeRange}
							setSize={(size:string) => updateField('companySizeRange', size)}
							isValid={!isInvalid(companySizeRange)}
						/>
					</FormContent>

					<FormContent>
						<ContentMainHeading>
							{translation.t('Contact details')}
						</ContentMainHeading>
						<Description>
							{translation.t('We\'d like to get in touch with the person responsible for business travel and employee perks  to discuss potential discounts for your business')}
						</Description>

						<CheckboxContainer>
							<Checkbox
								name="I'm the contact person"
								label={translation.t('I’m responsible for business travel in the company')}
								value={guestIsContactPerson}
								onChange={toggleGuestIsContactPerson}
							/>
						</CheckboxContainer>

						<FormInputStyled
							label={`${translation.t('Email of person to contact')}*`}
							name="Contact e-mail"
							type="email"
							value={contactEmail}
							handleChange={(value: string) => updateField('contactEmail', value)}
							isValid={!isInvalidEmail(contactEmail)}
							placeholder={guestIsContactPerson ? mainGuestCustomer.email : ''}
						/>

						<FormInputStyled
							label={`${translation.t('Name of person to contact')}*`}
							name="Contact name"
							value={contactPerson}
							handleChange={(value: string) => updateField('contactPerson', value)}
							isValid={!isInvalid(contactPerson)}
						/>

						<FormInputStyled
							label={`${translation.t('Job title')}*`}
							name="Contact job title"
							value={contactJobTitle}
							handleChange={(value: string) => updateField('contactJobTitle', value)}
							isValid={!isInvalid(contactJobTitle)}
						/>

					</FormContent>
				</Sections>
			</PageView>
		</form>
	)
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
	appState: selectAppState,
	mainGuestCustomer: selectMainGuestCustomer,
	businessLead: selectBusinessLead,
	reservation: selectReservation,
	isSubmitting: selectIsSubmitting,
})

const mapDispatchToProps = (dispatch: (...args: any[]) => void): DispatchProps => ({
	updateField: (fieldName, data) => dispatch(updateCurrentStepField(fieldName, data)),
	goToNextStep: (options) => dispatch(goToStep(undefined, options)),
	removeBusinessLead: () => dispatch(removeBusinessLead()),
	goToPrevStep: () => dispatch(goToPrevStep({ resetStepHistoryTip: true })),
})

export default connect(mapStateToProps, mapDispatchToProps)(BusinessLeadViewComponent)
